<template>
  <div class="home">
    <HomeLogo />
  </div>
</template>

<script>
import HomeLogo from '@/components/HomeLogo.vue';

export default {
  name: 'HomeView',
  components: {
    HomeLogo,
  },
};
</script>

<style scoped>
.home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 0.5rem;
  position: relative;
}

.home > * {
  margin-top: -100px; /* Adjust this value to move the image up or down */
}


</style>
