<template>
<div class="background-light-gray">
    <v-container class="main-container">
        <v-card color="#121212" class="mx-auto component-wrapper custom-card">
            <TTLogo />
        </v-card>

        <v-card color="#121212" class="mx-auto component-wrapper custom-card">
            <TTAddUrlForm />
        </v-card>

        <v-card color="#121212" class="mx-auto component-wrapper custom-card">
            <TTUrlsList />
        </v-card>
    </v-container>
</div>
</template>

<script>
import TTAddUrlForm from '@/components/TTAddUrlForm.vue'
import TTUrlsList from '@/components/TTUrlsList.vue'
import TTLogo from '@/components/TTLogo.vue'

export default {
    name: 'TackleTechView',
    components: {
        TTAddUrlForm,
        TTUrlsList,
        TTLogo
    }
}
</script>

<style scoped>
.custom-card {
  display: flex;
  align-items: center;
  justify-content: center;
}

.background-light-gray {
  background-color: #3c3c3c;
  /* Lighter gray color for the background */
  display: flex;
  width: 100%;
  height: 100%;
}

.component-wrapper {
  padding: 25px;
  margin-bottom: 10px;
  margin-top: 0px;
  /* max-width: 600px; Remove this line */
  width: auto; /* Add this line to allow component to take the space it needs */
  max-width: 95%; /* Limit the maximum width to 95% of the container */
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main-container > * + * {
  margin-top: 0px;
}
</style>
