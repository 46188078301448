<template>
<v-card class="search-card" outlined>
    <v-card-title class="search-card-title">Search</v-card-title>
    <v-card-text>
        <v-text-field v-model="searchTerm" variant="solo-filled" class="search-field" @keyup.enter="searchEntries"></v-text-field>
        <div class="search-button-container">
            <v-btn class="search-button" size="x-large" @click="searchEntries">Search</v-btn>
        </div>
        <div v-if="searchResults.length > 0">
            <table class="custom-table">
                <thead>
                    <tr>
                        <th class="centered sortable" @click="sortTable">
                            Brand
                            <span v-if="sortDirection === 'asc'">▲</span>
                            <span v-if="sortDirection === 'desc'">▼</span>
                        </th>
                        <th class="centered">Product</th>
                        <th class="centered">Variant</th>
                        <th class="centered">Location</th>
                        <th class="centered">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(entry, index) in searchResults" :key="index" @keyup.enter="saveEdits(entry)">
                        <td v-if="editing !== entry.id" data-label="Brand">{{ entry.brand }}</td>
                        <td v-else data-label="Brand">
                            <v-text-field v-model="editedEntry.brand" variant="solo-filled" density="compact"></v-text-field>
                        </td>
                        <td v-if="editing !== entry.id" data-label="Product">{{ entry.product }}</td>
                        <td v-else data-label="Product">
                            <v-text-field v-model="editedEntry.product" variant="solo-filled" density="compact"></v-text-field>
                        </td>
                        <td v-if="editing !== entry.id" data-label="Variant">{{ entry.variant }}</td>
                        <td v-else data-label="Variant">
                            <v-text-field v-model="editedEntry.variant" variant="solo-filled" density="compact"></v-text-field>
                        </td>
                        <td v-if="editing !== entry.id" data-label="Location">{{ entry.location }}</td>
                        <td v-else data-label="Location">
                            <v-text-field v-model="editedEntry.location" variant="solo-filled" density="compact"></v-text-field>
                        </td>
                        <td class="action-buttons" data-label="Action">
                            <v-btn v-if="editing !== entry.id" @click="startEditing(entry)" color="green" class="edit-button">Edit</v-btn>
                            <v-btn v-else @click="saveEdits(entry)" color="blue" class="edit-button">Save</v-btn>
                            <v-btn @click="deleteEntry(entry.id)" color="red" class="delete-button">Delete</v-btn>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </v-card-text>
    <v-dialog v-model="dialog" persistent max-width="650px" @click:outside="closeDialog">
        <v-card>
            <v-card-text class="d-flex justify-center align-center">
                <div v-if="dialogMessage">{{ dialogMessage }}</div>
                <div v-else>Dialog content...</div>
            </v-card-text>
            <v-card-actions class="d-flex justify-center align-center">
                <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-card>
</template>

<script>
import {
    ref,
    remove,
    set,
    onValue
} from "firebase/database";
import {
    connexDB
} from "@/firebase.js";

export default {
    data() {
        return {
            searchTerm: "",
            searchResults: [],
            editing: null,
            editedEntry: {
                brand: "",
                product: "",
                variant: "",
                location: ""
            },
            dialog: false,
            dialogMessage: '',
            sortDirection: 'asc'
        };
    },
    methods: {
        async searchEntries() {
            this.dialogMessage = '';
            const entriesRef = ref(connexDB);
            onValue(entriesRef, (snapshot) => {
                let results = [];
                const data = snapshot.val();
                for (let id in data) {
                    // Ensure that the properties exist before calling toLowerCase()
                    const brand = data[id].brand ? data[id].brand.toLowerCase() : '';
                    const product = data[id].product ? data[id].product.toLowerCase() : '';
                    const location = data[id].location ? data[id].location.toLowerCase() : '';
                    const variant = data[id].variant ? data[id].variant.toLowerCase() : '';

                    if (brand.includes(this.searchTerm.toLowerCase()) ||
                        product.includes(this.searchTerm.toLowerCase()) ||
                        location.includes(this.searchTerm.toLowerCase()) ||
                        variant.includes(this.searchTerm.toLowerCase())) {
                        results.push({
                            id: id,
                            ...data[id]
                        });
                    }
                }
                this.searchResults = results;
                if (this.sortDirection === 'asc') {
                    this.searchResults.sort((a, b) => a.brand.localeCompare(b.brand));
                } else {
                    this.searchResults.sort((a, b) => b.brand.localeCompare(a.brand));
                }
                if (this.searchResults.length === 0) {
                    this.dialogMessage = "No search results found.";
                    this.dialog = true;
                }
            });
        },

        startEditing(entry) {
            this.editing = entry.id;
            this.editedEntry = {
                ...entry
            };
        },
        async saveEdits(entry) {
            const entryRef = ref(connexDB, entry.id);
            try {
                await set(entryRef, this.editedEntry);
                this.editing = null;
                this.searchEntries(); // Refresh the search results
            } catch (error) {
                console.error(error);
            }
        },
        async deleteEntry(id) {
            const entryRef = ref(connexDB, id);
            try {
                await remove(entryRef);
                this.searchEntries(); // Refresh the search results
            } catch (error) {
                console.error(error);
            }
        },
        closeDialog() {
            this.dialog = false;
        },
        sortTable() {
            this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
            this.searchEntries(); // Refresh the search results
        }
    },
};
</script>

<style scoped>
.custom-table tbody td .v-text-field {
    height: 40px;
    /* Adjust the height as needed */
}

.action-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.search-card-title {
    background-color: #D7723E;
    color: #fff;
    padding: 5px;
    font-size: 30px;
    text-align: center;
}

.search-field {
    padding-top: 20px;
    margin: 0 auto;
    max-width: 300px;
}

.search-button-container {
    display: flex;
    justify-content: center;
}

.search-button {
    color: #fff;
    margin-bottom: 10px;
    margin-top: -5px;
    padding: 10px 50px;
    /* Adjust the padding here */
}

.delete-button {
    color: white;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
}

.custom-table thead th {
    background-color: #3f51b5;
    color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 8px;
    text-align: center;
}

.custom-table tbody td {
    padding: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 8px;
    text-align: center;
}

.custom-table tbody td {
    padding: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    text-align: center;
}

.centered {
    text-align: center;
}

@media screen and (max-width: 600px) {
    .custom-table thead {
        display: none;
    }

    .custom-table tr {
        margin-bottom: 10px;
        display: block;
        border-bottom: 2px solid #ddd;
    }

    .custom-table td {
        display: block;
        text-align: right;
        border-bottom: 1px dotted #ccc;
    }

    .custom-table td::before {
        content: attr(data-label);
        float: left;
        text-transform: uppercase;
        font-weight: bold;
    }

    .action-buttons {
        flex-direction: column;
    }

    .edit-button,
    .delete-button {
        margin-left: 15px;
    }
}

.search-card {
    border: 1px solid #444;
    max-width: 100%;
    margin: 0;
    padding: 0;
    background-color: #1d1d1d;
    /* Add this line */
}

.sortable {
    cursor: pointer;
}
</style>
