<template>
  <div>
    <DropDown />
  </div>
</template>

<script>
import DropDown from '@/components/DropDown.vue';

export default {
  name: 'DropDownView',
  components: {
    DropDown,
  },
};
</script>

<style scoped>

</style>
