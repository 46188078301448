<template>
<div class="flex-container">

    <!-- Invoice Form Section -->
    <div class="input-container">
        <h1 class="title">Input Invoice:</h1>
        <v-form @submit.prevent="submitInvoice">
            <v-text-field label="Vendor" v-model="invoice.vendor"></v-text-field>
            <v-text-field label="Invoice Date" v-model="invoice.date" type="date"></v-text-field>
            <v-text-field label="Due Date" v-model="invoice.dueDate" type="date"></v-text-field>
            <v-text-field label="Amount" v-model="invoice.amount" prefix="$"></v-text-field>

            <div class="button-container">
                <v-btn class="custom-button" type="submit">Submit</v-btn>
            </div>
        </v-form>
    </div>

    <!-- Overdue Invoices Section -->
    <div class="invoice-list-container overdue-invoices">
        <h2 class="invoice-list-title" style="color: red;">Overdue Invoices</h2>
        <v-list>
            <v-list-item v-for="(item, index) in overdueInvoices" :key="index">
                <v-list-item-content class="invoice-content">
                    <div>
                        <v-list-item-title style="text-align: left;">
                            {{ formatDate(item.dueDate) }} -
                            <a href="#" @click="openStatusModal(item)">{{ item.vendor }}</a>
                        </v-list-item-title>
                        <v-list-item-subtitle style="text-align: left;">
                            Invoice Date: {{ formatDate(item.date) }} - Amount: ${{ item.amount }}
                        </v-list-item-subtitle>
                    </div>
                    <v-list-item-action @click="openDeleteConfirmModal(item)">
                        <v-icon small class="delete-icon">mdi-delete</v-icon>
                    </v-list-item-action>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>

    <!-- Invoice List Section / HIDDEN -->
    <div class="invoice-master-list-container">
        <h2>Invoice List</h2>
        <v-list>
            <v-list-item v-for="(item, index) in invoices" :key="index">
                <v-list-item-content class="invoice-content">
                    <div>
                        <v-list-item-title>Due: {{ formatDate(item.dueDate) }} - {{ item.vendor }}</v-list-item-title>
                        <v-list-item-subtitle>Invoice Date: {{ formatDate(item.date) }} - Amount: ${{ item.amount }}</v-list-item-subtitle>
                    </div>
                    <v-list-item-action>
                        <input type="file" v-if="!item.invoicePDFUrl" @change="handleFileUpload($event, item.id)" />
                        <a v-else :href="item.invoicePDFUrl" target="_blank">Download Invoice</a>
                    </v-list-item-action>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>

    <!-- Calendar Section -->
    <div class="calendar-container">
        <div class="calendar-nav">
            <button @click="changeMonth(-1)">&lt; Prev</button>
            <span>{{ currentMonthLabel }}</span>
            <button @click="changeMonth(1)">Next &gt;</button>
        </div>
        <div class="calendar">
            <div class="calendar-row" v-for="(week, weekIndex) in calendarDays" :key="weekIndex">
                <div class="calendar-day" v-for="(day, dayIndex) in week" :key="dayIndex">
                    {{ day.date }}
                    <div v-for="invoice in filteredInvoices(day.fullDate)" :key="invoice.id" @click="openStatusModal(invoice)">
                        <span :style="{ color: invoice.status === 'Paid' ? 'green' : 'red' }">{{ invoice.vendor }}</span> - <span>${{ invoice.amount }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="downloadCalendarContainer">
        <button class="downloadCalendarButton" @click="downloadCalendarAsPDF">Download as PDF</button>
    </div>



    <!-- Invoice Status Modal -->
    <v-dialog v-model="statusDialog" persistent max-width="290">
        <v-card>
            <v-card-title>Select Status</v-card-title>
            <v-card-text>
                <v-radio-group v-model="selectedInvoiceStatus" label="Status">
                    <v-radio label="Not Paid" value="Not Paid"></v-radio>
                    <v-radio label="Paid" value="Paid"></v-radio>
                </v-radio-group>

                <!-- File Upload Field -->
                <input type="file" v-if="currentInvoice && !currentInvoice.invoicePDFUrl" @change="handleFileUpload($event, currentInvoice.id)" ref="fileInput">
                <a v-else-if="currentInvoice" :href="currentInvoice.invoicePDFUrl" target="_blank">Download Invoice</a>
                <div v-if="isUploading" class="loader"></div>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn color="green darken-1" text @click="updateInvoiceStatus">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- Invoice List Section for This Month's Invoices -->
<div class="invoice-list-container">
    <h2>This Month's Invoices (Unpaid)</h2>
    <v-list class="this-months-invoices-list">
        <v-list-item v-for="(item, index) in thisMonthsInvoices" :key="index">
                <v-list-item-content class="invoice-content">
                    <div>
                        <v-list-item-title>
                            Due: {{ formatDate(item.dueDate) }} -
                            <a href="#" @click="openStatusModal(item)">{{ item.vendor }}</a>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            Invoice Date: {{ formatDate(item.date) }} - Amount: ${{ item.amount }}
                        </v-list-item-subtitle>
                    </div>
                    <v-list-item-action @click="openDeleteConfirmModal(item)">
                        <v-icon small class="delete-icon">mdi-delete</v-icon>
                    </v-list-item-action>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>

    <!-- Invoice List Section for Invoices Due Next Month -->
    <div class="invoice-list-container">
        <h2>Next Month's Invoices</h2>
        <v-list>
            <v-list-item v-for="(item, index) in nextMonthInvoices" :key="index">
                <v-list-item-content class="invoice-content">
                    <div>
                        <v-list-item-title>Due: {{ formatDate(item.dueDate) }} -
                            <a href="#" @click="openStatusModal(item)">{{ item.vendor }}</a>
                        </v-list-item-title>
                        <v-list-item-subtitle>Invoice Date: {{ formatDate(item.date) }} - Amount: ${{ item.amount }}</v-list-item-subtitle>
                    </div>
                    <v-list-item-action @click="openDeleteConfirmModal(item)">
                        <v-icon small class="delete-icon">mdi-delete</v-icon>
                    </v-list-item-action>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>

    <!-- Invoice List Section for Invoices Due in Two Months -->
    <div class="invoice-list-container">
        <h2>Two Months Ahead Invoices</h2>
        <v-list>
            <v-list-item v-for="(item, index) in twoMonthsInvoices" :key="index">
                <v-list-item-content class="invoice-content">
                    <div>
                        <v-list-item-title>Due: {{ formatDate(item.dueDate) }} -
                            <a href="#" @click="openStatusModal(item)">{{ item.vendor }}</a>
                        </v-list-item-title>
                        <v-list-item-subtitle>Invoice Date: {{ formatDate(item.date) }} - Amount: ${{ item.amount }}</v-list-item-subtitle>
                    </div>
                    <v-list-item-action @click="openDeleteConfirmModal(item)">
                        <v-icon small class="delete-icon">mdi-delete</v-icon>
                    </v-list-item-action>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>

    <!-- Invoice List Section for Invoices Due in Three Months -->
    <div class="invoice-list-container">
        <h2>Three Months Ahead Invoices</h2>
        <v-list>
            <v-list-item v-for="(item, index) in threeMonthsInvoices" :key="index">
                <v-list-item-content class="invoice-content">
                    <div>
                        <v-list-item-title>Due: {{ formatDate(item.dueDate) }} -
                            <a href="#" @click="openStatusModal(item)">{{ item.vendor }}</a>
                        </v-list-item-title>
                        <v-list-item-subtitle>Invoice Date: {{ formatDate(item.date) }} - Amount: ${{ item.amount }}</v-list-item-subtitle>
                    </div>
                    <v-list-item-action @click="openDeleteConfirmModal(item)">
                        <v-icon small class="delete-icon">mdi-delete</v-icon>
                    </v-list-item-action>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>

    <!-- Flex container for Paid and Unpaid Invoice Lists -->
    <div class="invoice-lists-flex-container">

        <!-- Invoice List Section for Unpaid Invoices -->
        <div class="invoice-list-container unpaid-invoices">
            <h2 class="invoice-list-title" style="color: red; text-align: center;">Unpaid Invoices</h2>
            <v-list>
                <v-list-item v-for="(item, index) in unpaidInvoices" :key="index">
                    <v-list-item-content class="invoice-content">
                        <div>
                            <v-list-item-title style="text-align: left;">Due: {{ formatDate(item.dueDate) }} -
                                <a href="#" @click="openStatusModal(item)">{{ item.vendor }}</a>
                            </v-list-item-title>
                            <v-list-item-subtitle style="text-align: left;">Invoice Date: {{ formatDate(item.date) }} - Amount: ${{ item.amount }}</v-list-item-subtitle>
                        </div>
                        <v-list-item-action @click="openDeleteConfirmModal(item)">
                            <v-icon small class="delete-icon">mdi-delete</v-icon>
                        </v-list-item-action>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </div>

        <!-- Invoice List Section for Paid Invoices -->
        <div class="invoice-list-container paid-invoices">
            <h2 class="invoice-list-title" style="color: green; text-align: center;">Paid Invoices</h2>
            <v-list>
                <v-list-item v-for="(item, index) in paidInvoices" :key="index">
                    <v-list-item-content class="invoice-content">
                        <div>
                            <v-list-item-title style="text-align: left;">Due: {{ formatDate(item.dueDate) }} -
                                <a href="#" @click="openStatusModal(item)">{{ item.vendor }}</a>
                            </v-list-item-title>
                            <v-list-item-subtitle style="text-align: left;">Invoice Date: {{ formatDate(item.date) }} - Amount: ${{ item.amount }}</v-list-item-subtitle>
                        </div>
                        <v-list-item-action @click="openDeleteConfirmModal(item)">
                            <v-icon small class="delete-icon">mdi-delete</v-icon>
                        </v-list-item-action>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </div>
    </div>

    <!-- Delete Confirmation Modal -->
    <v-dialog v-model="deleteConfirmDialog" persistent max-width="290">
        <v-card>
            <v-card-title>Confirm Deletion</v-card-title>
            <v-card-text>Are you sure you want to delete this invoice?</v-card-text>
            <v-card-actions>
                <v-btn color="red darken-1" text @click="closeDeleteConfirmModal">Cancel</v-btn>
                <v-btn color="green darken-1" text @click="confirmDeletion">Yes</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import {
    ref as dataRef,
    push,
    remove,
    onValue,
    update
} from 'firebase/database';
import {
    invoicesDB
} from '@/firebase';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {
    ref as storageRef,
    uploadBytes
} from 'firebase/storage';
import {
    invoicesStorage
} from '@/firebase'; // Assuming you've already exported invoicesStorage
import {
    getDownloadURL
} from 'firebase/storage';

export default {
    name: 'InvoiceTracker',
    data() {
        return {
            invoice: {
                vendor: '',
                date: '',
                dueDate: '',
                amount: '',
                status: 'Not Paid',
                invoicePDFUrl: '', // Add this field for the PDF URL
                isUploadComplete: false, // Add this line
                isUploading: false,

            },
            invoices: [],
            currentMonth: new Date(),
            statusDialog: false,
            selectedInvoiceStatus: '',
            currentInvoice: null,
            deleteConfirmDialog: false,
            invoiceToDelete: null,
            invoiceToDownload: null, // Add this property to store the invoice for download
            invoiceUploadStatus: {}, // Track upload status for each invoice
            isUploading: false,

        };
    },
    mounted() {
        this.fetchInvoices();
    },
    watch: {
        'currentInvoice.invoicePDFUrl': function (newVal, oldVal) {
            if (newVal !== oldVal && newVal !== '') {
                this.isUploadComplete = true;
            }
        },
    },
    computed: {
    overdueInvoices() {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to 00:00:00 for accurate comparison

        return this.invoices
            .filter(invoice => {
                const dueDate = new Date(invoice.dueDate);
                // Check if due date is before today and invoice is not paid
                return dueDate < today && invoice.status === 'Not Paid';
            })
            .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate)); // Sort by due date
    },


        modalKey() {
            return this.currentInvoice ? this.currentInvoice.id : 'default-key';
        },
        paidInvoices() {
            return this.invoices.filter(invoice => invoice.status === 'Paid');
        },
        unpaidInvoices() {
            return this.invoices
                .filter(invoice => invoice.status !== 'Paid')
                .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
        },

        thisMonthsInvoices() {
            // Adjust this method to include overdue invoices
            const startOfMonth = new Date(this.currentMonth.getFullYear(), this.currentMonth.getMonth(), 1);
            const endOfMonth = new Date(this.currentMonth.getFullYear(), this.currentMonth.getMonth() + 1, 0);

            return this.invoices
                .filter(invoice => {
                    const dueDate = new Date(invoice.dueDate);
                    return dueDate >= startOfMonth && dueDate <= endOfMonth && invoice.status !== 'Paid';
                })
                .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
        },

        nextMonthInvoices() {
            // Adjust this method to include overdue invoices
            return this.getFutureInvoices(1)
                .filter(invoice => invoice.status !== 'Paid')
                .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
        },

        twoMonthsInvoices() {
            // Adjust this method to include overdue invoices
            return this.getFutureInvoices(2)
                .filter(invoice => invoice.status !== 'Paid')
                .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
        },

        threeMonthsInvoices() {
            // Adjust this method to include overdue invoices
            return this.getFutureInvoices(3)
                .filter(invoice => invoice.status !== 'Paid')
                .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
        },
        currentMonthLabel() {
            return this.currentMonth.toLocaleDateString('default', {
                month: 'long',
                year: 'numeric'
            });
        },
        calendarDays() {
            const days = [];
            const startOfMonth = new Date(Date.UTC(this.currentMonth.getFullYear(), this.currentMonth.getMonth(), 1));
            let date = new Date(startOfMonth);
            while (date.getUTCMonth() === this.currentMonth.getUTCMonth()) {
                const weekDays = [];
                for (let day = 0; day < 7; day++) {
                    if (date.getUTCMonth() === this.currentMonth.getUTCMonth()) {
                        weekDays.push({
                            date: date.getUTCDate(),
                            fullDate: new Date(date)
                        });
                    } else {
                        weekDays.push({
                            date: '',
                            fullDate: ''
                        });
                    }
                    date.setUTCDate(date.getUTCDate() + 1);
                }
                days.push(weekDays);
            }
            return days;
        },
    },
    methods: {
        filterInvoicesByMonth(monthsAhead) {
            const targetMonth = new Date(this.currentMonth.getFullYear(), this.currentMonth.getMonth() + monthsAhead, 1);
            return this.invoices.filter(invoice => {
                const dueDate = new Date(invoice.dueDate);
                return dueDate.getFullYear() === targetMonth.getFullYear() && dueDate.getMonth() === targetMonth.getMonth();
            });
        },

        // Call this method when the invoice submission and file upload are complete
        resetInvoiceForm() {
            // Reset the invoice data to initial state
            this.invoice = {
                vendor: '',
                date: '',
                dueDate: '',
                amount: '',
                status: 'Not Paid',
                invoicePDFUrl: '',
                isUploadComplete: false
            };

            // Also reset the currentInvoice to null
            this.currentInvoice = null;

            // Close the modal
            this.closeModal();
        },

        downloadInvoice() {
            if (this.invoiceToDownload && this.invoiceToDownload.invoicePDFUrl) {
                const invoicePDFUrl = this.invoiceToDownload.invoicePDFUrl;

                // Use Firebase Storage's ref to generate a downloadable URL
                const storageRef = storageRef(invoicesStorage, invoicePDFUrl);

                // Get the download URL for the file
                getDownloadURL(storageRef)
                    .then(downloadURL => {
                        // Create a download link for the PDF
                        const link = document.createElement('a');
                        link.href = downloadURL;
                        link.target = '_blank';
                        link.download = 'invoice.pdf'; // Set the desired filename
                        link.click();
                    })
                    .catch(error => {
                        console.error('Error getting download URL:', error);
                    });
            }
        },

        resetFileInput() {
            if (this.$refs.fileInput) {
                this.$refs.fileInput.value = '';
            }
        },

        handleFileUpload(event, invoiceId) {
            const file = event.target.files[0];
            if (!file) {
                console.error("No file selected for upload.");
                return;
            }

            this.isUploading = true; // Start uploading

            const filePath = `invoices/${invoiceId}/${file.name}`;
            const fileRef = storageRef(invoicesStorage, filePath);
            uploadBytes(fileRef, file)
                .then((snapshot) => {
                    return getDownloadURL(snapshot.ref);
                })
                .then((downloadURL) => {
                    this.updateInvoicePDFUrl(invoiceId, downloadURL);
                    this.resetInvoiceForm(); // Reset the form here after file upload
                    this.isUploading = false; // End uploading
                })
                .catch((error) => {
                    console.error('Error uploading file:', error);
                    this.isUploading = false; // End uploading
                });
        },

        updateInvoicePDFUrl(invoiceId, url) {
            const invoiceIndex = this.invoices.findIndex(inv => inv.id === invoiceId);
            if (invoiceIndex !== -1) {
                this.invoices[invoiceIndex].invoicePDFUrl = url;
                update(dataRef(invoicesDB, `invoices/${invoiceId}`), {
                    invoicePDFUrl: url
                });
                this.isUploadComplete = true;
            }
        },

        downloadCalendarAsPDF() {
            const calendarElement = document.querySelector('.calendar-container');
            calendarElement.classList.add('pdf-format', 'hide-buttons'); // Apply PDF specific styling and hide buttons

            html2canvas(calendarElement, {
                scale: 1,
                logging: true,
                useCORS: true
            }).then(canvas => {
                calendarElement.classList.remove('pdf-format', 'hide-buttons'); // Remove the classes after capturing

                const pdf = new jsPDF({
                    orientation: 'landscape',
                    unit: 'mm',
                    format: 'a4'
                });

                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = pdf.internal.pageSize.getHeight();

                // Calculate scale based on width and check if height fits
                let scale = pdfWidth / canvas.width;
                let scaledHeight = canvas.height * scale;

                // If scaled height exceeds PDF height, adjust scale to fit height
                if (scaledHeight > pdfHeight) {
                    scale = pdfHeight / canvas.height;
                    scaledHeight = canvas.height * scale;
                }

                // Center the calendar horizontally and vertically
                const xPosition = (pdfWidth - canvas.width * scale) / 2;
                const yPosition = (pdfHeight - scaledHeight) / 2;

                pdf.addImage(canvas.toDataURL('image/png'), 'PNG', xPosition, yPosition, canvas.width * scale, scaledHeight);
                pdf.save('calendar.pdf');
            });
        },

        submitInvoice() {
            this.selectedInvoiceStatus = "Not Paid"; // Reset the status to "Not Paid" for new invoices

            push(dataRef(invoicesDB, 'invoices'), this.invoice)
                .then((response) => {
                    // Set currentInvoice with new data and the newly obtained ID
                    this.currentInvoice = {
                        ...this.invoice,
                        id: response.key
                    };

                    // Open the modal for file upload
                    this.statusDialog = true;
                })
                .catch(error => {
                    console.error("Error adding invoice: ", error);
                });
        },

        fetchInvoices() {
            onValue(dataRef(invoicesDB, 'invoices'), (snapshot) => {
                this.invoices = [];
                snapshot.forEach(childSnapshot => {
                    let key = childSnapshot.key;
                    let data = childSnapshot.val();
                    this.invoices.push({
                        id: key,
                        ...data
                    });
                });
            });
        },
        deleteInvoice(index) {
            const invoiceId = this.invoices[index].id;
            if (invoiceId) {
                remove(dataRef(invoicesDB, 'invoices/' + invoiceId))
                    .then(() => {
                        this.fetchInvoices();
                    })
                    .catch(error => {
                        console.error("Error removing invoice: ", error);
                    });
            } else {
                console.error('Error: No invoice ID found for deletion.');
            }
        },
        formatDate(dateString) {
            return dateString;
        },
        changeMonth(step) {
            let newDate = new Date(this.currentMonth);
            newDate.setUTCMonth(newDate.getUTCMonth() + step);
            this.currentMonth = newDate;
        },
        filteredInvoices(fullDate) {
            if (!fullDate) return [];
            return this.invoices.filter(invoice => {
                const invoiceDate = new Date(invoice.dueDate + 'T00:00:00Z');
                return invoiceDate.getTime() === fullDate.getTime();
            });
        },
        openStatusModal(invoice) {
            this.currentInvoice = invoice;

            // Default to "Not Paid" status every time the modal is opened
            this.selectedInvoiceStatus = invoice.status || "Not Paid";

            // Check if the invoice already has a status and set it
            if (invoice && invoice.status) {
                this.selectedInvoiceStatus = invoice.status;
            }
            this.statusDialog = true;

            this.statusDialog = true;
            this.invoiceToDownload = invoice; // Store the invoice data to download
        },

        closeModal() {
            this.statusDialog = false;
        },

        updateInvoiceStatus() {
            if (this.currentInvoice) {
                this.currentInvoice.status = this.selectedInvoiceStatus;
                update(dataRef(invoicesDB, 'invoices/' + this.currentInvoice.id), {
                        status: this.selectedInvoiceStatus
                    })
                    .then(() => {
                        this.resetInvoiceForm(); // Call reset method here
                    })
                    .catch(error => {
                        console.error("Error updating invoice: ", error);
                    });
                this.fetchInvoices();
            }
        },

        openDeleteConfirmModal(invoice) {
            this.invoiceToDelete = invoice;
            this.deleteConfirmDialog = true;
        },
        closeDeleteConfirmModal() {
            this.deleteConfirmDialog = false;
        },
        confirmDeletion() {
            if (this.invoiceToDelete && this.invoiceToDelete.id) {
                remove(dataRef(invoicesDB, 'invoices/' + this.invoiceToDelete.id))
                    .then(() => {
                        this.fetchInvoices();
                        this.closeDeleteConfirmModal();
                    })
                    .catch(error => {
                        console.error("Error removing invoice: ", error);
                    });
            }
        },
        getFutureInvoices(monthsAhead) {
            const targetMonth = new Date(this.currentMonth.getFullYear(), this.currentMonth.getMonth() + monthsAhead, 1);
            return this.invoices.filter(invoice => {
                const dueDate = new Date(invoice.dueDate);
                return dueDate.getFullYear() === targetMonth.getFullYear() && dueDate.getMonth() === targetMonth.getMonth();
            });
        }
    }
}
</script>

<style>

.input-container,
.invoice-list-container,
.calendar-container,
.invoice-master-list-container,
.v-list-item,
.custom-button,
.v-dialog {
    border-radius: 10px;
}

/* Specific style for "Due:" in Overdue Invoices */
.overdue-invoices .v-list-item-title::before {
    content: "Due: ";
    color: red;
}

.invoice-master-list-container {
    display: none;
}

.downloadCalendarButton {
    padding-top: 10px;
}

/* Style for invoice items in various invoice lists */
.unpaid-invoices .v-list-item,
.paid-invoices .v-list-item,
.overdue-invoices .v-list-item,
.next-month-invoices .v-list-item,
.two-months-invoices .v-list-item,
.three-months-invoices .v-list-item,
.this-months-invoices-list .v-list-item {
    border-bottom: 5px solid #121212; /* Updated color for separation line */
    width: 600px;
    
}

/* Remove the border from the last item in each list */
.unpaid-invoices .v-list-item:last-child,
.paid-invoices .v-list-item:last-child,
.overdue-invoices .v-list-item:last-child,
.next-month-invoices .v-list-item:last-child,
.two-months-invoices .v-list-item:last-child,
.three-months-invoices .v-list-item:last-child,
.this-months-invoices-list .v-list-item:last-child {
    border-bottom: none;
}

/* Adjustments for "This Month's Invoices" container */
.this-months-invoices-list .v-list-item-title,
.this-months-invoices-list .v-list-item-subtitle {
    font-size: 20px;
    margin-bottom: 10px;
}

.this-months-invoices-list .delete-icon {
    font-size: 30px;
    cursor: pointer;
}

/* Adjustments for "Overdue Invoices" container */
.overdue-invoices .v-list-item-title,
.overdue-invoices .v-list-item-subtitle {
    font-size: 20px; /* Larger font size */
    margin-bottom: 10px; /* Bottom margin for spacing */
}

.overdue-invoices .delete-icon {
    font-size: 30px; /* Larger delete icon */
    cursor: pointer; /* Cursor changes to pointer on hover */
}

.this-months-invoices-list .v-list-item-content {
    padding: 15px;
}

.invoice-content {
    padding-top: 10px;
}

/* Adjustments for larger screens */
@media (min-width: 641px) {
    .invoice-lists-flex-container {
        flex-direction: row;
    }

    .invoice-list-container {
        width: 100%;
        margin: 0 10px;
    }
}

.v-card-actions {
    display: flex;
    justify-content: space-between;
}

.invoice-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.flex-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1600px;
    padding: 20px;
    padding-top: 0; /* Remove top padding */
}



.input-container {
    width: 100%;
    max-width: 400px;
    padding: 25px;
    padding-top: 15px; /* Remove top padding */
    margin-top: 10px; /* Remove top margin */
    margin-bottom: 0px;
    border: 5px solid #000;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
.button-container {
    margin-top: 5px;
    display: flex;
    justify-content: center;
}

.custom-button {
    background-color: #C8642C;
    color: #ffffff !important;
}

.custom-button:hover {
    background-color: darken(#C8642C, 30%);
}

.title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.rounded-inputs .v-text-field__slot input {
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.calendar-day div {
    margin-top: 5px;
    cursor: pointer; /* This will change the cursor to a pointer */
}

.invoice-list-container {
    width: 100%; /* Responsive width */
    max-width: 600px; /* Maximum width */
    margin: 0 auto;
    border-radius: 15px;
    padding-top: 25px;
    padding-bottom: 25px;
    box-sizing: border-box; /* Include padding and border in width calculation */
}

.delete-icon {
    color: red;
    cursor: pointer;
}

/* Adjust the calendar container */
.calendar-container {
    width: 1600px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Full-width calendar */
.calendar {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 1600px;
    height: 800px;
    margin: 0;
    padding-top: 10px;
    box-sizing: border-box;
}

.calendar-row {
    display: contents;
}

.calendar-day {
    padding-top: 10px;
    padding-bottom: 0px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
    font-weight: bold;
    color: #000000;

    border-top: 1px solid black;
}

.calendar-wrapper {
    display: flex;
    width: 100%;
    margin: 20px auto;
}

.calendar-day div {
    margin-top: 5px;
    cursor: pointer.
}

.calendar-nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    font-size: 25px;
    font-weight: bold;
}

.calendar-nav button {
    background-color: #1c1c1c;
    color: #FFFFFF;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
}

.calendar-nav button:hover {
    background-color: #4a4a4a;
}

.calendar-nav span {
    font-weight: bold;
    color: black; /* Change color to black */
}

.invoice-master-list-container {
    display: none.
}

  .unpaid-invoices, .paid-invoices {
        padding-left: 10px;
        padding-right: 10px;
    }

@media (max-width: 640px) {
    .calendar-nav button {
        padding: 3px 5px; /* Reduce padding */
        font-size: 12px; /* Reduce font size */
    }
}

.this-months-invoices-list .v-list-item-title,
    .this-months-invoices-list .v-list-item-subtitle,
    .overdue-invoices .v-list-item-title,
    .overdue-invoices .v-list-item-subtitle,
    .next-month-invoices .v-list-item-title,
    .next-month-invoices .v-list-item-subtitle,
    .two-months-invoices .v-list-item-title,
    .two-months-invoices .v-list-item-subtitle,
    .three-months-invoices .v-list-item-title,
    .three-months-invoices .v-list-item-subtitle,
    .paid-invoices .v-list-item-title,
    .paid-invoices .v-list-item-subtitle,
    .unpaid-invoices .v-list-item-title,
    .unpaid-invoices .v-list-item-subtitle {
        font-size: 18px; /* Reduce font size for mobile devices */
        margin-top: 0px;
    }

/* Add media query for mobile devices */
@media (max-width: 640px) {
    .this-months-invoices-list .v-list-item-title,
    .this-months-invoices-list .v-list-item-subtitle,
    .overdue-invoices .v-list-item-title,
    .overdue-invoices .v-list-item-subtitle,
    .next-month-invoices .v-list-item-title,
    .next-month-invoices .v-list-item-subtitle,
    .two-months-invoices .v-list-item-title,
    .two-months-invoices .v-list-item-subtitle,
    .three-months-invoices .v-list-item-title,
    .three-months-invoices .v-list-item-subtitle,
    .paid-invoices .v-list-item-title,
    .paid-invoices .v-list-item-subtitle,
    .unpaid-invoices .v-list-item-title,
    .unpaid-invoices .v-list-item-subtitle {
        font-size: 12px; /* Reduce font size for mobile devices */
        margin-top: 10px;
    }
}

/* Media query for mobile devices */
@media (max-width: 640px) {
    .invoice-list-container,
    .invoice-lists-flex-container,
    .content-wrapper,
    .calendar-container,
    .calendar {
        width: 100%;
        margin: 0 auto;
        padding: 10px.
    }

    .unpaid-invoices, .paid-invoices {
        width: 100%;
        padding: 0;
    }

    .this-months-invoices-list .v-list-item-title,
    .this-months-invoices-list .v-list-item-subtitle,
    .overdue-invoices .v-list-item-title,
    .overdue-invoices .v-list-item-subtitle {
        font-size: 16px.
    }

    .this-months-invoices-list .delete-icon,
    .overdue-invoices .delete-icon {
        font-size: 20px.
    }

    .invoice-lists-flex-container {
        flex-direction: column;
        align-items: center.
    }
}

.justify-center {
    justify-content: center;
    display: flex;
    width: 100%.
}

.loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite.
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Flex container for invoice lists */
.invoice-lists-flex-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin-bottom: 20px.
}

</style>
