<template>
  <v-app>
    <v-main>
      <v-container>
        <v-text-field v-model="searchQuery" label="Search" @keyup.enter="searchProducts"></v-text-field>
        <v-data-table :headers="headers" :items="products" class="elevation-1">
          <template v-slot:[`item.action`]="{ item }">
            <v-btn color="primary" @click="editProduct(item)">Edit</v-btn>
          </template>
        </v-data-table>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      searchQuery: '',
      products: [],
      headers: [
        { text: 'Title', value: 'title' },
        { text: 'Price', value: 'price' },
        { text: 'Actions', value: 'action', sortable: false },
      ],
    }
  },
  methods: {
    async searchProducts() {
      // Replace this with your actual API endpoint
      const response = await axios.get(`http://localhost:8080/api/products?search=${this.searchQuery}`)
      this.products = response.data
    },
    editProduct(product) {
      this.$router.push({ name: 'EditProduct', params: { id: product.id } })
    },
  },
}
</script>
