<template>
  <v-card class="search-card" outlined>
    <h2>DELETE FROM DATABASE</h2>
    <v-card-text>
      <v-text-field
        ref="urlInput"
        v-model="searchTerm"
        variant="solo-filled"
        class="search-field"
        @keyup.enter="searchEntries"
      ></v-text-field>
      <div class="search-button-container">
        <v-btn class="search-button" size="x-large" @click="searchEntries">Search</v-btn>
      </div>
      <div v-if="searchResults.length > 0" class="search-results">
        <div v-for="(entry, index) in searchResults" :key="index" class="result-item">
          <div class="result-product">{{ entry.productName }}</div>
          <div class="result-url" v-html="entry.formattedUrl"></div>
          <div class="result-actions">
            <v-btn @click="deleteEntry(entry.id)" color="red" class="delete-button">Delete</v-btn>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-dialog v-model="dialog" persistent max-width="650px" @click:outside="closeDialog">
      <v-card>
        <v-card-text class="d-flex justify-center align-center">
          <div v-if="dialogMessage">{{ dialogMessage }}</div>
          <div v-else>Dialog content...</div>
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { ref, remove, onValue } from "firebase/database";
import { ttDB } from "@/firebase.js";

export default {
  data() {
    return {
      searchTerm: "",
      searchResults: [],
      dialog: false,
      dialogMessage: "",
    };
  },
  methods: {
    async searchEntries() {
      this.dialogMessage = "";
      const entriesRef = ref(ttDB);
      onValue(entriesRef, (snapshot) => {
        let results = [];
        const data = snapshot.val();
        for (let id in data) {
          if (
            (data[id].url &&
              data[id].url.toLowerCase().includes(this.searchTerm.toLowerCase())) ||
            (data[id].productName &&
              data[id].productName.toLowerCase().includes(this.searchTerm.toLowerCase()))
          ) {
            results.push({
              id: id,
              url: data[id].url,
              formattedUrl: data[id].url.replace(/\//g, '/​'),
              productName: data[id].productName
            });
          }
        }
        this.searchResults = results;
        if (this.searchResults.length === 0) {
          this.dialogMessage = "No search results found.";
          this.dialog = true;
        }
      });
    },
    async deleteEntry(id) {
      const entryRef = ref(ttDB, id);
      try {
        // Check if it is a duplicate and show the dialog
        const isDuplicate = this.checkForDuplicate();
        if (isDuplicate) {
          this.dialogMessage = "This entry is a duplicate!";
          this.dialog = true;
          this.searchTerm = ""; // Clear the URL input field
        } else {
          // Delete the entry
          await remove(entryRef);
        }
      } catch (error) {
        console.error(error);
      }
    },
    closeDialog() {
      this.dialog = false;
    },
    checkForDuplicate() {
      // Implement your logic to check for duplicates
      // Return true if it is a duplicate, false otherwise
    },
    truncateURL(url) {
      const maxWidth = 600; // Maximum viewport width for mobile devices
      const maxLength = maxWidth >= window.innerWidth ? 18 : 30; // Maximum length for truncated URL

      if (url && url.length > maxLength) {
        let truncatedURL = url.slice(0, maxLength);
        const lastSpaceIndex = truncatedURL.lastIndexOf(" ");
        const lastSlashIndex = truncatedURL.lastIndexOf("/");
        const lastDotIndex = truncatedURL.lastIndexOf(".");
        const endIndex = Math.max(lastSpaceIndex, lastSlashIndex, lastDotIndex);
        truncatedURL = truncatedURL.slice(0, endIndex);
        return truncatedURL + "...";
      }
      return url;
    }
  }
};
</script>


<style scoped>
h2 {
    text-align: center;
    margin-bottom: -25px;
    font-size: 24pt;
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.search-field {
    padding-top: 20px;
    margin: 0 auto;
    max-width: 300px;
}

.search-button-container {
    display: flex;
    justify-content: center;
}

.search-button {
    color: #fff;
    margin-bottom: 5px;
    margin-top: -5px;
    padding: 10px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.delete-button {
    margin-bottom: 10px;
}

.search-results {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.result-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
    border-bottom: 1px solid white;
    padding: 5px 0;
}

.result-url,
.result-product,
.result-actions {
    display: flex;
    justify-content: center;
}

.result-product {
    font-weight: bold;
}

.result-url {
    width: 100%;
    word-break: break-all;
    text-align: center;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
}

.result-actions {
    margin-top: 10px;
}

.search-card {
   border: 1px solid #444;
}
</style>
