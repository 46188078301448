<template>
<v-app v-theme="{ defaultTheme: 'dark' }">
    <v-navigation-drawer v-model="drawer" app class="drawer" dark>
        <v-list dense nav>
            <router-link to="/">
                <v-list-item value="logo" class="logo-item">
                    <img src="@/assets/logo.png" alt="Copperstate" class="drawer-logo">
                </v-list-item>
            </router-link>

            <v-list-item @click="checkPasswordAndNavigate('/invoices')" class="logo-item">
                <img src="@/assets/invoices.png" alt="Invoices" class="drawer-logo">
            </v-list-item>

            <!-- <router-link to="/bigrock">
                <v-list-item value="bigrock" class="logo-item">
                    <img src="@/assets/brlogo.png" alt="Big Rock" class="drawer-logo">
                </v-list-item>
            </router-link>

            <router-link to="/pitmancreek">
                <v-list-item value="pitmancreek" class="logo-item">
                    <img src="@/assets/pclogo.png" alt="Pitman Creek" class="drawer-logo">
                </v-list-item>
            </router-link> -->

            <router-link to="/connex">
                <v-list-item value="connex" class="logo-item">
                    <img src="@/assets/connex.png" alt="Connex" class="drawer-logo">
                </v-list-item>
            </router-link>

            <router-link to="/inventory">
                <v-list-item value="stock" class="stock">
                    <img src="@/assets/invmanagement.png" alt="Inv M" class="drawer-logo">
                </v-list-item>
            </router-link>

            <router-link to="/sponsoredguys">
                <v-list-item value="sponsoredguys" class="sponsoredguys">
                    <h1>
                        Sponsored Guys
                    </h1>
                </v-list-item>
            </router-link>

               <!-- <router-link to="/dropdown">
                <v-list-item value="dropdown" class="dropdown">
                    <h1>
                        Drop Down Menu
                    </h1>
                </v-list-item>
            </router-link> -->

        </v-list>
    </v-navigation-drawer>

    <v-app-bar app class="app-bar" dark>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>Copperstate Tackle</v-toolbar-title>
    </v-app-bar>

    <v-main class="main-content">
        <router-view></router-view>
    </v-main>

    <!-- Password Modal -->
    <v-dialog v-model="passwordDialog" persistent max-width="290">
        <v-card>
            <v-card-title class="headline">Enter Password</v-card-title>
            <v-card-text>
                <v-text-field v-model="maskedInput" label="Password" type="text" inputmode="numeric" autofocus @input="handleInput" @keyup.enter="submitPassword"></v-text-field>
            </v-card-text>
<v-card-actions class="d-flex justify-center">
    <v-btn color="blue darken-1" text @click="passwordDialog = false">Cancel</v-btn>
</v-card-actions>

            
        </v-card>
    </v-dialog>

</v-app>
</template>

<script>
import '@mdi/font/css/materialdesignicons.css';
import {
    mapState
} from 'vuex';

export default {
    data() {
        return {
            drawer: false,
            correctPassword: '241623',
            passwordDialog: false, // To control the visibility of the modal
            passwordInput: '', // To store the entered password
            maskedInput: '', // To display the masked input

        };
    },
    computed: {
        ...mapState(['isAuthenticated'])
    },
    watch: {
        isAuthenticated(isAuth) {
            if (isAuth) {
                this.drawer = true;
            }
        },
        passwordInput(newVal) {
            if (newVal.length === 6) { // Check if length is 6
                this.submitPassword();
            }
        },
    },
    methods: {
        handleInput(event) {
            const lastChar = event.data;
            if (lastChar && /[0-9]/.test(lastChar)) {
                this.passwordInput += lastChar;
            } else if (event.inputType === 'deleteContentBackward') {
                this.passwordInput = this.passwordInput.slice(0, -1);
            }
            this.maskedInput = '•'.repeat(this.passwordInput.length);
        },
        checkPasswordAndNavigate(to) {
            // Open the modal
            this.passwordDialog = true;
            this.navigateTo = to; // Store the navigation target
        },
        submitPassword() {
            if (this.passwordInput === this.correctPassword) {
                this.$router.push(this.navigateTo);
                this.passwordDialog = false;
            } else {
                alert('Incorrect password!');
            }
            this.passwordInput = '';
            this.maskedInput = ''; // Reset the masked input
        },
    }

};
</script>

<style scoped>
/* Set the background color for the main content area */
.main-content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* Set the background color for the top bar */
.v-app-bar {
    background-color: #191919;
}

/* Set the logo styles in the drawer */
.drawer-logo {
    width: 100%;
    /* Set the width to 100% */
    height: 100%;
    /* Set the height to 100% */
    max-width: 250px;
    max-height: 250px;
    margin: auto;
    display: block;
}

/* Common styles for all v-list-item elements */
.logo-item {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 15px;
}

/* Set the hover state styles for v-list-item elements */
.logo-item:hover {
    background-color: #00000;
    cursor: pointer;
}

/* Set the background color for the app bar */
.app-bar {
    background-color: #191919 !important;
    ;
}
</style>
