import { createStore } from 'vuex';
import { getAuth, onAuthStateChanged } from "firebase/auth";

export default createStore({
state: {
  isAuthenticated: false,
  authReady: false,
  authInitialized: false,  // New state
},
mutations: {
  setAuth(state, payload) {
    state.isAuthenticated = payload;
  },
  setAuthReady(state, payload) {
    state.authReady = payload;
  },
  setAuthInitialized(state, payload) {  // New mutation
    state.authInitialized = payload;
  },
},
actions: {
  setAuth({ commit }, payload) {
    commit('setAuth', payload);
  },
  initializeAuth({ commit }) {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        commit('setAuth', true);
      } else {
        commit('setAuth', false);
      }
      commit('setAuthReady', true);
      commit('setAuthInitialized', true);  // Set authInitialized to true
    });
  },
},
getters: {
  isAuthenticated: state => state.isAuthenticated,
  authReady: state => state.authReady,
  authInitialized: state => state.authInitialized,  // New getter
}

});
