<template>
  <div>
    <v-dialog v-model="dialog" max-width="500px" overlay overlay-color="rgba(0, 0, 0, 0.8)">
      <v-card>
        <v-card-title>{{ alertTitle }}</v-card-title>
        <v-card-text>{{ alertMessage }}</v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="dialog = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card class="form-card" outlined>
      <v-card-title class="form-card-title">Add Entry</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="submitForm">
          <h2 class="section-title">Brand</h2>
          <v-text-field v-model="brand" required variant="solo-filled" class="input-fields brand-input" ref="brandInput"></v-text-field>
          <h2 class="section-title">Product</h2>
          <v-text-field v-model="product" required variant="solo-filled" class="input-fields product-input"></v-text-field>
          <h2 class="section-title">Variant</h2>
          <v-text-field v-model="variant" required variant="solo-filled" class="input-fields variant-input"></v-text-field>
          <h2 class="section-title">Location</h2>
          <v-text-field v-model="location" required variant="solo-filled" class="location-input-field"></v-text-field>
          <div class="submit-button-container">
            <v-btn size="x-large" class="submit-button" type="submit">Submit</v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, set } from "firebase/database";
import { connexDB } from "@/firebase.js";
import { v4 as uuidv4 } from 'uuid';

export default {
  data() {
    return {
      dialog: false,
      alertTitle: '',
      alertMessage: '',
      brand: '',
      product: '',
      location: '',
      variant: '',
    };
  },
  methods: {
    async submitForm() {
      if (this.brand === '' || this.product === '' || this.location === '' || this.variant === '') {
        this.showAlert('Error', 'Please fill in all the fields.');
        return;
      }

      const uuid = uuidv4(); // Generate a UUID
      const payload = {
        id: uuid,
        brand: this.brand,
        product: this.product,
        location: this.location,
        variant: this.variant,
      };

      try {
        await set(ref(connexDB, uuid), payload);
        this.showAlert('Success', 'Entry added successfully!!!');
        this.brand = '';
        this.product = '';
        this.location = '';
        this.variant = '';
        this.$refs.brandInput.focus();
      } catch (error) {
        this.showAlert('Error', 'An error occurred');
        console.error(error);
      }
    },

    showAlert(title, message) {
      this.alertTitle = title;
      this.alertMessage = message;
      this.dialog = true;
    },
  },
};
</script>

<style scoped>
.form-card {
    max-width: 650px;
    margin: 0 auto;
    border: 1px solid #444;
    background-color: #1d1d1d; 
}

.form-card-title {
    background-color: #D7723E;
    color: #fff;
    padding: 5px 15px; /* Changed the top-bottom padding from 15px to 5px */
    font-size: 30px;
    text-align: center;
    margin-bottom: 20px;
}

.form-card-text {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.submit-button-container {
    display: flex;
    justify-content: center;
    margin-top: -5px;
}

.submit-button {
    color: #fff;
    margin-bottom: 5px;
    margin-top: 0px;
    padding: 10px 50px;
}

.input-fields {
    max-width: 350px;
    margin: 0 auto;
}

.location-input-field {
    max-width: 125px;
    margin: 0 auto;
}

.section-title {
    text-align: center;
    margin-bottom: 5px;
    font-size: 18px;
    margin-top: -15px;
    padding-top: 10px;
}
</style>
