<template>
<v-container>
    <v-form @submit.prevent="onSubmit">
        <!-- Form Fields -->
        <v-text-field v-model="formData.name" label="Name"></v-text-field>
        <v-text-field v-model="formData.nickname" label="Nickname"></v-text-field>
        <v-text-field v-model="formData.phoneNumber" label="Phone Number" type="tel"></v-text-field>
        <v-text-field v-model="formData.address" label="Address"></v-text-field>
        <v-text-field v-model="formData.monthlyAllowance" label="Monthly Allowance" type="number"></v-text-field>
        <v-text-field v-model="formData.monthlyPayment" label="Monthly Payment" type="number"></v-text-field>
        <v-text-field v-model="formData.contractStartDate" label="Contract Start Date" type="date"></v-text-field>
        <v-text-field v-model="formData.contractEndDate" label="Contract End Date" type="date"></v-text-field>
        <v-textarea v-model="formData.otherSponsorships" label="Other Sponsorships"></v-textarea>
        <v-textarea v-model="formData.notes" label="Notes"></v-textarea>

        <!-- File Upload -->
        <v-file-input @change="onFileChange" label="Document"></v-file-input>

        <!-- Submit Button -->
        <v-btn type="submit">Upload</v-btn>
    </v-form>

    <!-- Displaying Submissions -->
    <div v-for="item in submissions" :key="item.id">
      <p>
        {{ item.name }} - 
        {{ item.nickname }} - 
        {{ item.phoneNumber }} - 
        {{ item.address }} -
        {{ item.monthlyAllowance }} - 
        {{ item.monthlyPayment }} - 
        {{ formatDate(item.contractStartDate) }} - 
        {{ formatDate(item.contractEndDate) }} - 
        {{ item.otherSponsorships }} - 
        {{ item.notes }}
      </p>
      <a :href="item.downloadURL" target="_blank">Download File</a>
        <v-btn @click="editSubmission(item)">Edit</v-btn>
        <v-btn @click="deleteSubmission(item)">Delete</v-btn>
    </div>
</v-container>
</template>

<script>
import { 
  ref as storageRef, 
  uploadBytes, 
  getDownloadURL, 
  deleteObject 
} from 'firebase/storage';
import { 
  collection, 
  addDoc, 
  getDocs, 
  doc, 
  updateDoc, 
  deleteDoc 
} from 'firebase/firestore';
import { db, storage } from '../firebase';

export default {
  data() {
    return {
      formData: {
        name: '',
        nickname: '',
        phoneNumber: '',
        address: '',
        monthlyAllowance: 0,
        monthlyPayment: 0,
        contractStartDate: '',
        contractEndDate: '',
        otherSponsorships: '',
        notes: '',
      },
      file: null,
      submissions: [],
      editMode: false,
      currentSubmissionId: null,
    };
  },
  methods: {
    formatDate(value) {
      if (value) {
        return new Date(value).toLocaleDateString();
      }
      return '';
    },
    onFileChange(e) {
      this.file = e.target.files[0];
    },
    async onSubmit() {
      if (this.editMode) {
        await this.updateSubmission();
      } else {
        await this.createSubmission();
      }
    },
    async createSubmission() {
      if (!this.file) {
        alert("Please select a file before submitting.");
        return;
      }

      const fileRef = storageRef(storage, `/sponsored/${this.file.name}`);
      await uploadBytes(fileRef, this.file);
      const downloadURL = await getDownloadURL(fileRef);

      const submissionData = {
        ...this.formData,
        downloadURL,
        fileName: this.file.name,
      };

      await addDoc(collection(db, "submissions"), submissionData);
      this.resetForm();
      this.fetchSubmissions();
    },
    resetForm() {
      this.formData = {
        name: '',
        nickname: '',
        phoneNumber: '',
        address: '',
        monthlyAllowance: 0,
        monthlyPayment: 0,
        contractStartDate: '',
        contractEndDate: '',
        otherSponsorships: '',
        notes: '',
      };
      this.file = null;
      this.editMode = false;
      this.currentSubmissionId = null;
    },
    async fetchSubmissions() {
      const querySnapshot = await getDocs(collection(db, "submissions"));
      this.submissions = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    },
    async editSubmission(item) {
      this.currentSubmissionId = item.id;
      this.formData = { ...item };
      this.editMode = true;
      this.file = null; // Reset file input
    },
    async updateSubmission() {
      let fileURL = this.formData.downloadURL;
      if (this.file) {
        // Delete the old file
        const oldFileRef = storageRef(storage, `/sponsored/${this.formData.fileName}`);
        await deleteObject(oldFileRef);

        // Upload the new file
        const newFileRef = storageRef(storage, `/sponsored/${this.file.name}`);
        await uploadBytes(newFileRef, this.file);
        fileURL = await getDownloadURL(newFileRef);
      }

      const submissionRef = doc(db, "submissions", this.currentSubmissionId);
      await updateDoc(submissionRef, {
        ...this.formData,
        downloadURL: fileURL,
        fileName: this.file ? this.file.name : this.formData.fileName
      });

      this.resetForm();
      this.fetchSubmissions();
    },
    async deleteSubmission(item) {
      // Delete file from Storage
      const fileRef = storageRef(storage, `/sponsored/${item.fileName}`);
      await deleteObject(fileRef);

      // Delete document from Firestore
      const submissionRef = doc(db, "submissions", item.id);
      await deleteDoc(submissionRef);

      this.fetchSubmissions();
    },
  },
  mounted() {
    this.fetchSubmissions();
  }
};
</script>


<style>
/* Add your styles here */
</style>
