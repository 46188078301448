import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { onAuthStateChanged } from 'firebase/auth';
import { createRouter, createWebHistory } from 'vue-router';
import store from './store'; 
import { getStorage } from 'firebase/storage';  // Import getStorage


import HomeView from './views/HomeView.vue';
import LoginView from './views/LoginView.vue';
import TackleTechView from './views/TackleTechView.vue';
import BigRockView from './views/BigRockView.vue';
import PitmanCreekView from './views/PitmanCreekView.vue';
import ConnexView from './views/ConnexView.vue';
import LowInventoryView from './views/LowInventoryView.vue';
import SponsoredGuysView from './views/SponsoredGuysView.vue';
import DropDownView from './views/DropDownView.vue';
import InvoiceView from './views/InvoiceView.vue';

const connexConfig = {
  apiKey: "AIzaSyD8J4_PeB9m_te7_DC0iFdqjORIgB_K-KQ",
  authDomain: "crawlerproject-e0d20.firebaseapp.com",
  databaseURL: "https://crawlerproject-e0d20-default-rtdb.firebaseio.com",
  projectId: "crawlerproject-e0d20",
  storageBucket: "crawlerproject-e0d20.appspot.com",
  messagingSenderId: "640298099084",
  appId: "1:640298099084:web:5f018e9ca32e2f47d5b6a9",
  measurementId: "G-YCZPGFVCNK"
};

const ttConfig = {
    apiKey: "AIzaSyD8J4_PeB9m_te7_DC0iFdqjORIgB_K-KQ",
    authDomain: "crawlerproject-e0d20.firebaseapp.com",
    databaseURL: "https://crawlerproject-e0d20-urls.firebaseio.com/",
    projectId: "crawlerproject-e0d20",
    storageBucket: "crawlerproject-e0d20.appspot.com",
    messagingSenderId: "640298099084",
    appId: "1:640298099084:web:5f018e9ca32e2f47d5b6a9",
    measurementId: "G-YCZPGFVCNK"
  };

  const loginConfig = {
    // Firebase configuration for the login database
    apiKey: "AIzaSyD8J4_PeB9m_te7_DC0iFdqjORIgB_K-KQ",
    authDomain: "crawlerproject-e0d20.firebaseapp.com",
    databaseURL: "https://crawlerproject-e0d20-tt.firebaseio.com",
    projectId: "crawlerproject-e0d20",
    storageBucket: "crawlerproject-e0d20.appspot.com",
    messagingSenderId: "640298099084",
    appId: "1:640298099084:web:5f018e9ca32e2f47d5b6a9",
    measurementId: "G-YCZPGFVCNK"
  };

  const stockConfig = {
    apiKey: "AIzaSyD8J4_PeB9m_te7_DC0iFdqjORIgB_K-KQ",
    authDomain: "crawlerproject-e0d20.firebaseapp.com",
    databaseURL: "https://crawlerproject-e0d20-stock.firebaseio.com",
    projectId: "crawlerproject-e0d20",
    storageBucket: "crawlerproject-e0d20.appspot.com",
    messagingSenderId: "640298099084",
    appId: "1:640298099084:web:5f018e9ca32e2f47d5b6a9",
    measurementId: "G-YCZPGFVCNK"
  };

  const allproductsConfig = {
    apiKey: "AIzaSyD8J4_PeB9m_te7_DC0iFdqjORIgB_K-KQ",
    authDomain: "crawlerproject-e0d20.firebaseapp.com",
    databaseURL: "https://crawlerproject-e0d20-allproducts.firebaseio.com/",
    projectId: "crawlerproject-e0d20",
    storageBucket: "crawlerproject-e0d20.appspot.com",
    messagingSenderId: "640298099084",
    appId: "1:640298099084:web:5f018e9ca32e2f47d5b6a9",
    measurementId: "G-YCZPGFVCNK"
  };

  const incomingConfig = {
    apiKey: "AIzaSyD8J4_PeB9m_te7_DC0iFdqjORIgB_K-KQ",
    authDomain: "crawlerproject-e0d20.firebaseapp.com",
    databaseURL: "https://crawlerproject-e0d20-incoming.firebaseio.com/",
    projectId: "crawlerproject-e0d20",
    storageBucket: "crawlerproject-e0d20.appspot.com",
    messagingSenderId: "640298099084",
    appId: "1:640298099084:web:5f018e9ca32e2f47d5b6a9",
    measurementId: "G-YCZPGFVCNK"
  };

  const sponsorsConfig = {
    apiKey: "AIzaSyD8J4_PeB9m_te7_DC0iFdqjORIgB_K-KQ",
    authDomain: "crawlerproject-e0d20.firebaseapp.com",
    databaseURL: "https://crawlerproject-e0d20-incoming.firebaseio.com/",
    projectId: "crawlerproject-e0d20",
    storageBucket: "crawlerproject-e0d20.appspot.com",
    messagingSenderId: "640298099084",
    appId: "1:640298099084:web:5f018e9ca32e2f47d5b6a9",
    measurementId: "G-YCZPGFVCNK"
  };

  const invoicesConfig = {
    apiKey: "AIzaSyD8J4_PeB9m_te7_DC0iFdqjORIgB_K-KQ",
    authDomain: "crawlerproject-e0d20.firebaseapp.com",
    databaseURL: "https://crawlerproject-e0d20-incoming.firebaseio.com/",
    projectId: "crawlerproject-e0d20",
    storageBucket: "crawlerproject-e0d20.appspot.com",
    messagingSenderId: "640298099084",
    appId: "1:640298099084:web:5f018e9ca32e2f47d5b6a9",
    measurementId: "G-YCZPGFVCNK"
  };


  const connexApp = initializeApp(connexConfig, 'connexApp');
  const ttApp = initializeApp(ttConfig, 'ttApp');
  const loginApp = initializeApp(loginConfig, 'loginApp');
  const stockApp = initializeApp(stockConfig, 'stockApp');
  const allproductsApp = initializeApp(allproductsConfig, 'allproductsApp');
  const incomingApp = initializeApp(incomingConfig, 'incomingApp');
  const sponsorsApp = initializeApp(sponsorsConfig, 'sponsorsApp');
  const invoicesApp = initializeApp(invoicesConfig, 'invoicesApp');

  
  const auth = getAuth(invoicesApp);
  
  const connexDB = getDatabase(connexApp);
  const ttDB = getDatabase(ttApp);
  const loginDB = getDatabase(loginApp);
  const stockDB = getDatabase(stockApp);
  const allproductsDB = getDatabase(allproductsApp);
  const incomingDB = getDatabase(incomingApp);
  const sponsorsDB = getDatabase(sponsorsApp);
  const invoicesDB = getDatabase(invoicesApp);

  // Initialize Firebase Storage
const invoicesStorage = getStorage(invoicesApp);
  
  export { connexDB, ttDB, loginDB, auth, stockDB, allproductsDB, incomingDB, sponsorsDB, invoicesDB, invoicesStorage };

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/tackletech',
    name: 'Tackle Tech Crawler',
    component: TackleTechView,
    meta: { requiresAuth: true }
  },
  {
    path: '/bigrock',
    name: 'Big Rock Crawler',
    component: BigRockView,
    meta: { requiresAuth: true }
  },
  {
    path: '/pitmancreek',
    name: 'Pitman Creek Crawler',
    component: PitmanCreekView,
    meta: { requiresAuth: true }
  },
  {
    path: '/connex',
    name: 'Connex',
    component: ConnexView,
    meta: { requiresAuth: true }
  },
  {
    path: '/inventory',
    name: 'Inventory',
    component: LowInventoryView,
    meta: { requiresAuth: true }
  },
  {
    path: '/sponsoredguys',
    name: 'Sponsored Guys',
    component: SponsoredGuysView,
    meta: { requiresAuth: true }
  },

  {
    path: '/dropdown',
    name: 'Drop Down Menu',
    component: DropDownView,
    meta: { requiresAuth: true }
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: InvoiceView,
    meta: { requiresAuth: true }
  },
  
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  });
  
  router.beforeEach((to, from, next) => {
    const auth = getAuth();
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  
    if (requiresAuth && !auth.currentUser) {
      next({ name: 'login' });
    } else {
      next();
    }
  });

  onAuthStateChanged(auth, (user) => {
    if (user) {
      store.dispatch('setAuth', true);
    } else {
      store.dispatch('setAuth', false);
    }
    store.dispatch('setAuthReady', true);  // sets authReady to true
  });
  
  
  export default router;