<template>
    <div class="pclogo">
        <img src="../assets/pclogo.png" alt="Pittman Creek Logo" class="logo">    
    </div>
    <div class="component-wrapper">
        <TTAddUrlForm />
    </div>
    <div class="component-wrapper">
        <TTUrlsList />
    </div>
</template>

<script>
import TTAddUrlForm from '@/components/TTAddUrlForm.vue'
import TTUrlsList from '@/components/TTUrlsList.vue'

export default {
    name: 'PittmanCreekView',
    components: {
        TTAddUrlForm,
        TTUrlsList
    }
}
</script>

<style scoped>
.logo {
  width: 80%;
  height: auto;
  max-width: 300px;
  margin: auto;
  display: block;
}

.component-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
}

.pclogo {
    padding-top: 30px;
}

@media screen and (max-width: 240px) {
  .list-item {
    max-width: 80%;
text-align: center; /* add this */  
  }
}
</style>
