<template>
  <div class="container">
    <!-- Add modal markup -->
    <v-dialog v-model="showModal" max-width="400" @click:outside="closeModal">
      <v-card>
        <v-card-title class="headline">Success</v-card-title>
        <v-card-text>URL and Product Name added successfully!</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="toggleModal">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="inner-card">
      <form @submit.prevent="submitForm" class="form">
        <h2>ADD PRODUCT</h2>
        <div class="form-group">
          <label for="url" class="form-label">URL:</label>
          <v-text-field
            variant="solo-filled"
            type="url"
            id="url"
            v-model="url"
            required
            class="form-input-url"
            full-width
            autocomplete="off"
          ></v-text-field>
        </div>

        <div class="form-group">
          <label for="productName" class="form-label">Product Name:</label>
          <v-text-field
            variant="solo-filled"
            type="text"
            id="productName"
            v-model="productName"
            required
            class="form-input-product-name"
            full-width
            autocomplete="off"
          ></v-text-field>
        </div>

        <div class="submit-btn-container">
          <v-btn size="large" type="submit" class="submit-button">Submit</v-btn>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, set, get } from "firebase/database";
import { ttDB } from "@/firebase.js";
import { v4 as uuidv4 } from "uuid";

export default {
  data() {
    return {
      url: "",
      productName: "",
      showModal: false,
    };
  },
  methods: {
    closeModal(event) {
      if (event.target.classList.contains("modal")) {
        this.showModal = false;
      }
    },
    toggleModal() {
      this.showModal = !this.showModal;
      if (!this.showModal) {
        this.url = ""; // Reset the URL input field
      }
    },
    async submitForm() {
      const id = uuidv4(); // Generate a unique ID
      const payload = {
        id,
        url: this.url,
        productName: this.productName,
      };

      try {
        // Check for duplicate URL
        const urlSnapshot = await get(ref(ttDB));
        const duplicateUrl = Object.values(urlSnapshot.val() || {}).find(
          (entry) => entry.url === this.url
        );

        if (duplicateUrl) {
          alert("Duplicate URL found. Please enter a unique URL.");
          return;
        }

        // Check for duplicate product name
        const productNameSnapshot = await get(ref(ttDB));
        const duplicateProductName = Object.values(productNameSnapshot.val() || {}).find(
          (entry) => entry.productName === this.productName
        );

        if (duplicateProductName) {
          alert("Duplicate Product Name found. Please enter a unique Product Name.");
          return;
        }

        // Add new URL and product name to the database
        const entryRef = ref(ttDB, id); // The reference now includes the generated UUID
        await set(entryRef, payload);

        this.url = "";
        this.productName = "";
        this.showModal = true;
      } catch (error) {
        console.error(error);
        alert("An error occurred while adding the URL and Product Name.");
      }
    },
  },
};
</script>


<style scoped>
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.inner-card {
  background-color: #202020;
  padding: 0px;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  /* Add a border */
  border: 1px solid #444; /* Change color as needed */
}

.inner-card:not(:last-child) {
  border-bottom: 1px solid #444; /* Change color as needed */
}


h2 {
  text-align: center;
  margin-bottom: 10px;
  font-size: 24pt;
  margin-top: 5px;
}

.form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  
}

.form-label {
  display: block;
  margin-bottom: 5px;
  margin-top: -5px;
  font-weight: bold;
  text-align: center;
}

.form-input-url:-webkit-autofill,
.form-input-product-name:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #202020 inset !important;
  -webkit-text-fill-color: #fff !important;
}

/* Non-media query CSS */

.form-input-url, .form-input-product-name {
  box-sizing: border-box;
  padding: 0 10px; /* Added 10px horizontal padding */
  font-size: 16px;
  border-radius: 5px;
  margin-top: -5px;
  width: 100%; /* Set default width to 100% */
}

/* Media query for larger screens */

@media (min-width: 481px) {
  .form-input-url {
    width: 600px;
  }

  .form-input-product-name {
    width: 250px;
  }
}

/* Media query for mobile screens */

@media (max-width: 480px) {
  .form-input-url {
    width: 275px;
  }

  .form-input-product-name {
    width: 150px;
  }

  .inner-card {
    width: 100%; /* Set width to 100% for inner card on mobile */
    padding: 15px;
    margin: auto; /* Center the card */
  }
}



.submit-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.submit-button {
 color: #fff;
    margin-bottom: 15px;
    margin-top: -15px;
    padding: 10px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
