<template>
  <h2 class="connex-title">Connex Management System</h2>
  <div class="page-container">
    <div class="component-wrapper-form">
      <ConnexForm />
    </div>
    <div class="component-wrapper-delete">
      <ConnexDelete />
    </div>
  </div>
</template>

<script>
import ConnexForm from '@/components/ConnexForm.vue';
import ConnexDelete from '@/components/ConnexDelete.vue';

export default {
  name: 'ConnexView',
  components: {
    ConnexForm,
    ConnexDelete,
  },
};
</script>

<style scoped>
.connex-title {
  font-size: 52px;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 0;
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px; /* Add horizontal padding */
}

.component-wrapper-form {
  margin: 5px; /* reduce the margin from 10px to 5px */
  padding-top: 5px; /* reduce the padding from 10px to 5px */
  width: 550px;
  max-width: 100%;
  box-sizing: border-box;
}

.component-wrapper-delete {
  margin: 5px; /* reduce the margin from 10px to 5px */
  padding-top: 5px; /* reduce the padding from 10px to 5px */
}

@media (max-width: 768px) {
  .connex-title {
    font-size: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px;
  }
}
</style>
