<template>
  <div class="fill-height">
    <v-card>
      <h2 class="text-center">Login</h2>
      <v-form ref="form" @submit.prevent="login" class="text-center">
        <v-text-field v-model="email" type="email" label="Email"></v-text-field>
        <v-text-field v-model="password" type="password" label="Password"></v-text-field>
        <v-btn color="blue" @click="login" type="submit">Login</v-btn>
      </v-form>
      <v-snackbar v-model="showAlert" :color="alertColor" top>
        {{ alertMessage }}
      </v-snackbar>
    </v-card>
  </div>
</template>



<script>
import { ref, onValue } from 'firebase/database';
import { loginDB } from '../firebase';

export default {
  data() {
    return {
      email: '',
      password: '',
      showAlert: false,
      alertMessage: '',
      alertColor: ''
    };
  },
  methods: {
    async login() {
      console.log('Login method triggered');
      if (!this.email || !this.password) {
        this.showAlert = true;
        this.alertMessage = 'Please fill in both fields.';
        this.alertColor = 'error';
        return;
      }

      const usersRef = ref(loginDB);
      onValue(usersRef, (snapshot) => {
        const users = snapshot.val();
        console.log('Users from Firebase:', users);

        if (users) {
          const foundUser = Object.values(users).find(user => user.email === this.email && user.password === this.password);
          console.log('Found user:', foundUser);

          if (foundUser) {
              this.$store.dispatch('setAuth', true); // Set isAuthenticated to true
      this.$router.push('/');
          } else {
            this.showAlert = true;
            this.alertMessage = 'Invalid email or password.';
            this.alertColor = 'error';
          }
        } else {
          this.showAlert = true;
          this.alertMessage = 'No users found.';
          this.alertColor = 'error';
        }
      });
    }
  }
};
</script>

<style scoped>
.v-form {
  margin-bottom: 16px;
}

.fill-height {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-card {
  width: 550px;
  padding: 20px;
  box-sizing: border-box;
  margin: auto;
}

@media (max-width: 480px) {
.v-card {
  width: 350px;
  padding: 20px;
  box-sizing: border-box;
  margin: auto;
}
}
</style>

