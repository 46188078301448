<template>
  <div class="logo-container-home">
    <img alt="Vue logo" src="../assets/logo.png" class="logo-home">
  </div>
</template>

<script>
export default {
  name: 'HomeLogo',
};
</script>

<style>
.logo-container-home {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-home {
  width: 100%;
  max-width: 300px;
  height: auto;
}

@media only screen and (max-width: 768px) {
  .logo-home {
    max-width: 200px;
  }
}

@media only screen and (max-width: 480px) {
  .logo-home {
    max-width: 150px;
  }
}

</style>
