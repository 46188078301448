import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index.js'; // The path to your Vuex store
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import TackleTechView from '../views/TackleTechView.vue';
import BigRockView from '../views/BigRockView.vue';
import PitmanCreekView from '../views/PitmanCreekView.vue';
import ConnexView from '../views/ConnexView.vue';
import LowInventoryView from '../views/LowInventoryView.vue';
import SponsoredGuysView from '../views/SponsoredGuysView.vue';
// import DropDownView from '../views/DropDownView.vue';
import InvoiceView from '../views/InvoiceView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/tackletech',
    name: 'Tackle Tech Crawler',
    component: TackleTechView,
    meta: { requiresAuth: true },
  },
  {
    path: '/bigrock',
    name: 'Big Rock Crawler',
    component: BigRockView,
    meta: { requiresAuth: true },
  },
  {
    path: '/pitmancreek',
    name: 'Pitman Creek Crawler',
    component: PitmanCreekView,
    meta: { requiresAuth: true },
  },
  {
    path: '/connex',
    name: 'Connex',
    component: ConnexView,
    meta: { requiresAuth: true },
  },
  {
    path: '/inventory',
    name: 'Inventory',
    component: LowInventoryView,
    meta: { requiresAuth: true }
  },
  {
    path: '/sponsoredguys',
    name: 'Sponsored Guys',
    component: SponsoredGuysView,
    meta: { requiresAuth: true }
  },

  // {
  //   path: '/dropdown',
  //   name: 'Drop Down Menu',
  //   component: DropDownView,
  //   meta: { requiresAuth: true }
  // },
  {
    path: '/invoices',
    name: 'Invoices',
    component: InvoiceView,
    meta: { requiresAuth: true }
  }
];

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !store.state.isAuthenticated) {
    next({ name: 'login' });
  } else {
    // Scroll to top before navigating to the next route
    scrollToTop();
    next();
  }
});
export default router;
