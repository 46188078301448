<template>
<v-card class="search-card" outlined>
    <v-card-text>
        <div class="search-input-container">
            <div class="clear-button-container">
                <v-btn class="search-button" size="x-large" @click="downloadData">
                    Download
                </v-btn>
            </div>
        </div>

        <div v-if="searchResults.length > 0" class="table-container">
            <table class="custom-table">
                <thead>
                    <tr>
                        <th class="centered sortable" @click="sortTable('Handle')">Handle</th>
                        <th v-show="columnPopulated['Option1 Value']" class="centered sortable" @click="sortTable('Option1 Value')">Variant</th>
                        <th v-show="columnPopulated['Option2 Value']" class="centered sortable" @click="sortTable('Option2 Value')">Option</th>
                        <th v-show="columnPopulated['Option3 Value']" class="centered sortable" @click="sortTable('Option3 Value')">Option</th>
                        <th v-show="columnPopulated['Variant Price']" class="centered sortable" @click="sortTable('Variant Price')">Price</th>
                        <th class="centered sortable" @click="sortTable('SKU')">SKU</th>
                        <th class="centered sortable available-column" @click="sortTable('Available')">Available</th>
                        <th class="centered sortable need-column" @click="sortTable('need')">Need</th>
                        <th v-show="!skuSearched" class="centered action-column">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(entry, index) in searchResults" :key="index" :class="{ 'highlight-row': isHighlighted(entry, index) }">
                        <td>{{ entry.Handle }}</td>
                        <td v-show="columnPopulated['Option1 Value']">{{ entry['Option1 Value'] }}</td>
                        <td v-show="columnPopulated['Option2 Value']">{{ entry['Option2 Value'] }}</td>
                        <td v-show="columnPopulated['Option3 Value']">{{ entry['Option3 Value'] }}</td>
                        <td v-show="columnPopulated['Variant Price']">{{ entry['Variant Price'] }}</td>
                        <td>{{ entry.SKU }}</td>
                        <td class="available-column" :data-label="editing !== entry.id ? 'Available' : ''">
                            <template v-if="editing !== entry.id">
                                {{ entry.Available }}
                            </template>
                            <template v-else>
                                <v-text-field v-model="editedEntry.Available" variant="solo-filled" class="edit-input"></v-text-field>
                            </template>
                        </td>
                        <td class="need-column" :data-label="editing !== entry.id ? 'Need' : ''">
                            <template v-if="editing !== entry.id">
                                {{ entry.need }}
                            </template>
                            <template v-else>
                                <v-text-field v-model="editedEntry.need" variant="solo-filled" class="edit-input"></v-text-field>
                            </template>
                        </td>
                        <td v-show="!skuSearched" :data-label="'Action'" class="action-column" :class="{ 'editing-cell': editing === entry.id }">
                            <template v-if="editing !== entry.id">
                                <div class="button-container">
                                    <v-btn @click="startEditing(entry)" color="green" class="edit-button">Edit</v-btn>
                                </div>
                                <div class="button-container">
                                    <v-btn @click="clearNeedData(entry.id)" color="red" class="clear-button">Clear</v-btn>
                                </div>
                            </template>
                            <template v-else>
                                <div class="button-container">
                                    <v-btn @click="saveEdits(entry)" color="blue" class="edit-button">Save</v-btn>
                                </div>
                                <div class="button-container">
                                    <v-btn @click="cancelEditing()" color="red" class="cancel-button">Cancel</v-btn>
                                </div>
                                <div class="button-container">
                                    <v-btn @click="deleteEntry(entry.id)" color="black" class="delete-button">Delete</v-btn>
                                </div>
                            </template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </v-card-text>
</v-card>
</template>


<script>
import {
    ref,
    set,
    remove,
    get,
    onValue,
    off
} from "firebase/database";
import {
    allproductsDB
} from "@/firebase.js";
import {
    saveAs
} from 'file-saver';
import SkuModal from './SkuModal.vue';

export default {
    data() {
        return {
            components: {
                'sku-modal': SkuModal,
            },
            modalEntries: [],
            searchHandle: "",
            searchTerm: "",
            searchResults: [],
            skuSearched: false,
            editing: null,
            editedEntry: {
                Handle: "",
                "Option1 Value": "",
                "Option2 Value": "",
                "Option3 Value": "",
                Price: "",
                Available: "",
                need: "",
            },
            sortField: "",
            sortDirection: "asc",
            // columnPopulated: {
            //     "Option1 Value": false,
            //     "Option2 Value": false,
            //     "Option3 Value": false,
            //     "Variant Price": false, // Update the property name to "Variant Price"
            //     need: false,
            // },
            highlightedIndex: -1,
        };
    },

    watch: {
        searchResults() {
            if (this.sortField) {
                this.sortResults();
            }
        },
    },

    computed: {
        highlightedTableShowing() {
            return this.skuSearched && this.highlightedIndex !== -1;
        },
    },
    created() {
        const entriesRef = ref(allproductsDB);
        onValue(entriesRef, (snapshot) => {
            const data = snapshot.val();
            this.updateData(data);
            this.searchEntries();
        });
    },

    beforeUnmount() {
        const entriesRef = ref(allproductsDB);
        off(entriesRef);
    },
    methods: {
        openSkuModal(sku) {
            // Filter your data to find entries with the same handle.
            this.modalEntries = this.data.filter(entry => entry.SKU === sku);

            // Open the modal.
            this.$refs.skuModal.open();
        },

        downloadData() {
            let htmlContent = "<html><body>";

            // Define the original headers
            const originalHeaders = ["Handle", "Option1 Value", "Option2 Value", "Option3 Value", "Available", "need", "SKU"];

            // Filter the headers to only include those with at least one non-empty value
            const headers = originalHeaders.filter(header => this.searchResults.some(row => row[header]));

            this.searchResults.forEach((row, index) => {
                headers.forEach((header) => {
                    let data = row[header];

                    // Add the header and data to the htmlContent
                    htmlContent += `<p>${header}: ${data}</p>`;
                });

                // If this is not the last row, add a horizontal rule to separate entries
                if (index < this.searchResults.length - 1) {
                    htmlContent += '<hr>';
                }
            });

            htmlContent += "</body></html>";

            const blob = new Blob([htmlContent], {
                type: "text/html;charset=utf-8;"
            });
            saveAs(blob, "table_data.doc");
        },

        // downloadData() {
        //       let txtContent = "";

        //       // Define the original headers
        //       const originalHeaders = ["Handle", "Option1 Value", "Option2 Value", "Option3 Value", "Available", "need", "SKU"];

        //       // Filter the headers to only include those with at least one non-empty value
        //       const headers = originalHeaders.filter(header => this.searchResults.some(row => row[header]));

        //       this.searchResults.forEach((row, index) => {
        //         headers.forEach((header) => {
        //           let data = row[header];

        //           // Add the header and data to the txtContent
        //           txtContent += `${header}: ${data}\n`;
        //         });

        //         // If this is not the last row, add a blank line to separate entries
        //         if (index < this.searchResults.length - 1) {
        //           txtContent += '\n';
        //         }
        //       });

        //       const blob = new Blob([txtContent], {type: "text/plain;charset=utf-8;"});
        //       saveAs(blob, "table_data.txt");
        //     },

        //    downloadData() {
        //   let csvContent = "";

        //   // Define the original headers
        //   const originalHeaders = ["Handle", "Option1 Value", "Option2 Value", "Option3 Value", "Available", "need", "SKU"];

        //   // Filter the headers to only include those with at least one non-empty value
        //   const headers = originalHeaders.filter(header => this.searchResults.some(row => row[header]));

        //   csvContent += headers.join(",") + "\r\n";

        //   this.searchResults.forEach((row) => {
        //     let rowData = [];
        //     headers.forEach((header) => {
        //       let data = row[header];

        //       // If the data contains a comma, wrap it in quotes
        //       if (data && data.toString().includes(',')) {
        //         data = `"${data}"`;
        //       }

        //       // If the header is "SKU", prepend a single quote
        //       if (header === "SKU") {
        //         data = `'${data}`;
        //       }

        //       rowData.push(data);
        //     });
        //     csvContent += rowData.join(",") + "\r\n";
        //   });

        //   const blob = new Blob([csvContent], {type: "text/csv;charset=utf-8;"});
        //   saveAs(blob, "table_data.csv");
        // },

        resetColumnPopulated() {
            this.columnPopulated = {
                "Option1 Value": false,
                "Option2 Value": false,
                "Option3 Value": false,
                "Variant Price": false,
                need: false,
            };
        },

        updateData(data) {
            let searchTerm = this.searchTerm.trim();
            let searchHandle = null;
            this.resetColumnPopulated(); // Reset the column populated states before each search
            this.skuSearched = searchTerm !== "";

            let matchingEntries = [];
            let otherEntries = [];

            for (let id in data.products) {
                const entry = data.products[id];
                if (searchTerm === "") {
                    if (entry.Need && entry.Need.trim() !== "") {
                        otherEntries.push(this.formatEntry(id, entry)); // Add the entry to otherEntries
                        this.updateColumnPopulated(entry); // Update the column populated states
                    }
                } else {
                    if (entry.SKU === searchTerm) {
                        searchHandle = entry.Handle;
                        matchingEntries.push(this.formatEntry(id, entry)); // Add the SKU match entry to matchingEntries
                        this.updateColumnPopulated(entry); // Update the column populated states
                    } else if (
                        entry.Handle === searchHandle &&
                        !matchingEntries.some((match) => match.SKU === entry.SKU)
                    ) {
                        matchingEntries.push(this.formatEntry(id, entry)); // Add the matching handle entry to matchingEntries
                        this.updateColumnPopulated(entry); // Update the column populated states
                    }
                }
            }

            // Find all entries with the same handle as the searched SKU
            if (searchHandle) {
                for (let id in data.products) {
                    const entry = data.products[id];
                    if (
                        entry.Handle === searchHandle &&
                        entry.SKU !== searchTerm &&
                        !matchingEntries.some((match) => match.SKU === entry.SKU)
                    ) {
                        matchingEntries.push(this.formatEntry(id, entry)); // Add the matching handle entry to matchingEntries
                        this.updateColumnPopulated(entry); // Update the column populated states
                    }
                }
            }

            // Concatenate the matchingEntries and otherEntries
            const results = [...matchingEntries, ...otherEntries];
            this.searchResults = results;
            if (this.sortField) {
                this.sortResults();
            }
            this.highlightedIndex = this.searchResults.findIndex(
                (entry) => entry.SKU === searchTerm
            );

            console.log(this.searchResults); // Add this line to log the search results to the console
        },

        async searchEntries() {
            const entriesRef = ref(allproductsDB);
            onValue(entriesRef, (snapshot) => {
                const data = snapshot.val();
                this.updateData(data);
            });
        },

        updateColumnPopulated(entry) {
            for (let field in this.columnPopulated) {
                if (entry[field] && entry[field].trim() !== "") {
                    this.columnPopulated[field] = true;
                }
            }
        },

        formatEntry(id, entry) {
            return {
                id: id,
                Handle: this.formatHandle(entry.Handle),
                "Option1 Value": entry["Option1 Value"],
                "Option2 Value": entry["Option2 Value"],
                "Option3 Value": entry["Option3 Value"],
                "Variant Price": `${entry["Variant Price"]}`, // Add a dollar sign in front of the price
                Available: entry.Available,
                need: entry.Need || "", // Update to include the 'Need' property
                SKU: entry.SKU,
            };
        },

        formatHandle(handle) {
            if (!handle) return "";
            const words = handle.split("-");
            const transformedWords = words.map(
                (word) => word.charAt(0).toUpperCase() + word.slice(1)
            );
            return transformedWords.join(" ");
        },

        clearNeedData(id) {
            const entryRef = ref(allproductsDB, `products/${id}/Need`);
            try {
                set(entryRef, "");
                this.searchEntries();
            } catch (error) {
                console.error(error);
            }
        },

        startEditing(entry) {
            this.editing = entry.id;
            this.editedEntry.Available = entry.Available;
            this.editedEntry.need = entry.need;
        },

        cancelEditing() {
            this.editing = null;
            this.editedEntry = {
                Handle: "",
                "Option1 Value": "",
                "Option2 Value": "",
                "Option3 Value": "",
                Price: "",
                Available: "",
                need: "",
            };
        },

        async saveEdits(entry) {
            const entryRef = ref(allproductsDB, `products/${entry.id}`);
            try {
                let needValue = this.editedEntry.need;
                let availableValue = this.editedEntry.Available;

                if (needValue === undefined || needValue.trim() === "") {
                    needValue = ""; // Set to empty string or any default value
                }

                console.log(`Fetching product data for product ${entry.id}`);
                const snapshot = await get(entryRef);
                if (!snapshot.exists()) {
                    console.error(`No product found with id ${entry.id}`);
                    return;
                }

                let productData = snapshot.val();
                console.log(`Updating 'Need' value for product ${entry.id}`);
                productData.Need = String(needValue);
                productData.Available = String(availableValue);

                // Set the Variant and Option properties
                productData.Variant = entry["Option1 Value"];
                productData.Option1 = entry["Option2 Value"];
                productData.Option2 = entry["Option3 Value"];

                console.log(`Writing updated product data back to Firebase for product ${entry.id}`);
                await set(entryRef, productData);
                console.log(`Updated product data written to Firebase for product ${entry.id}`);

                this.editing = null;
                console.log(`Refreshing table...`);
                await this.searchEntries(); // Refresh the results table
                console.log(`Table refreshed.`);
            } catch (error) {
                console.error(`Error updating product ${entry.id}:`, error);
            }
        },

        async deleteEntry(id) {
            const entryRef = ref(allproductsDB, `products/${id}`);
            try {
                await remove(entryRef);
                await this.searchEntries(); // Refresh the results table
            } catch (error) {
                console.error(`Error deleting entry with id ${id}: `, error);
            }
        },

        sortTable(field) {
            if (this.sortField === field) {
                this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
            } else {
                this.sortField = field;
                this.sortDirection = "asc";
            }

            this.sortResults();

        },

        sortResults() {
            const field = this.sortField;
            const direction = this.sortDirection;
            this.searchResults.sort((a, b) => {
                let valueA = a[field];
                let valueB = b[field];

                if (field === "Available" || field === "Need") {
                    valueA = valueA ? parseInt(valueA) : 0;
                    valueB = valueB ? parseInt(valueB) : 0;
                } else if (field === "Variant Price") {
                    valueA = valueA ? parseFloat(valueA) : 0;
                    valueB = valueB ? parseFloat(valueB) : 0;
                } else {
                    valueA = valueA !== undefined && valueA !== null ? String(valueA) : "";
                    valueB = valueB !== undefined && valueB !== null ? String(valueB) : "";
                }

                if (valueA < valueB) {
                    return direction === "asc" ? -1 : 1;
                } else if (valueA > valueB) {
                    return direction === "asc" ? 1 : -1;
                }
                return 0;
            });
        },

        isHighlighted(entry, index) {
            return (
                this.skuSearched && // Only highlight rows if SKU is searched
                ((this.highlightedIndex === -1 && index === 0) || // Highlight the first row if highlightedIndex is -1
                    this.highlightedIndex === index)
            );
        },

    },
};
</script>

<style scoped>
.clickable {
    cursor: pointer;
}

.pointer {
    cursor: pointer;
}

.need-column,
.available-column {
    width: 100px;
    /* or any other value that suits your needs */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.search-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5px;
    padding-top: 15px;
}

.search-field-container {
    margin-bottom: 10px;
}

.clear-field-button {
    font-size: 15px;
    margin-bottom: 5px;
    margin-top: -10px;
    max-height: 30px;
    color: gray;
    background-color: white;
    /* Set the default background color */
    transition: color 0.3s ease, background-color 0.3s ease;
    /* Add transition for smooth color change */
}

.clear-field-button:hover {
    color: black;
    /* Change text color to white on hover */
    background-color: lightgray;
    /* Change background color to light gray on hover */
}

.clear-button-container {
    display: flex;
    justify-content: center;
}

.highlight-row {
    background-color: #eaf2fd;
    color: black;
}

.delete-button {
    margin-top: 10px;
    color: white;
}

.search-card-handle {
    background-color: #102ef0;
    color: #fff;
    padding: 5px;
    font-size: 30px;
    text-align: center;
}

.search-field {
    padding-top: 20px;
    margin: 0 auto;
    width: 250px;
    /* Set the width to 250px */
    max-width: 100%;
    /* Ensure it doesn't exceed 100% of the parent container */
}

.search-button-container {
    display: flex;
    justify-content: center;
}

.search-button {
    color: #fff;
    margin-bottom: 10px;
    margin-top: -15px;
    padding: 10px 50px;

}

.custom-table {
    width: 100%;
    table-layout: fixed;
}

.table-container {
    overflow-x: auto;
}

.custom-table thead .action-column,
.custom-table tbody .action-column {
    width: 200px;
}

.custom-table thead th {
    background-color: #3f51b5;
    color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 8px;
    text-align: center;
}

.custom-table tbody td {
    padding: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    text-align: center;
}

.centered {
    text-align: center;
}

@media screen and (max-width: 600px) {
    .custom-table thead {
        display: none;
    }

    .custom-table tr {
        margin-bottom: 10px;
        display: block;
        border-bottom: 2px solid #ddd;
        padding: 10px;
        /* Add some padding */
        background-color: #f8f8f8;
        /* Add a background color */
    }

    .custom-table td {
        display: block;
        text-align: right;
        border-bottom: 1px dotted #ccc;
    }

    .custom-table td::before {
        content: attr(data-label);
        float: left;
        text-transform: uppercase;
        font-weight: bold;
    }

    .action-buttons {
        flex-direction: column;
    }

    .button-container {
        margin-right: 10px;
    }
}

.search-card {
    border: 1px solid #444;
    max-width: 100%;
    margin: 0;
    padding: 0;
    background-color: #1d1d1d;
}

.sortable {
    cursor: pointer;
}

.edit-button {
    margin-bottom: 10px;
}
</style>
