<template>
  <div class="home">
    <img class="logo" :src="require('@/assets/logo.png')" alt="Logo" />
    <LoginPage />
  </div>
</template>

<script>
import LoginPage from '@/components/LoginPage.vue';

export default {
  name: 'LoginView',
  components: {
    LoginPage,
  },
};
</script>

<style scoped>
.home {
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  height: 250px;
  margin: 0;
  padding: 0;
}
</style>
