<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Handle</th>
                <th class="text-left">SKU</th>
                <!-- add more columns as needed -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="entry in filteredEntries" :key="entry.id">
                <td>{{ entry.Handle }}</td>
                <td>{{ entry.SKU }}</td>
                <!-- add more data as needed -->
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['entries', 'sku'],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    filteredEntries() {
      if (!this.sku) {
        // If no SKU is clicked, return all entries
        return this.entries;
      } else {
        // Filter entries based on the clicked SKU's Handle
        const handle = this.entries.find(entry => entry.SKU === this.sku)?.Handle;
        return this.entries.filter(entry => entry.Handle === handle);
      }
    },
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
  },
  watch: {
    sku(newValue) {
      if (newValue) {
        this.open();
      } else {
        this.close();
      }
    },
  },
};
</script>
