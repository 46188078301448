<template>
  <div class="logo-container">
    <img src="../assets/ttlogo.png" alt="Tackle Tech Logo" class="logo">
  </div>
</template>

<style scoped>
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
}

.logo {
  width: 400px; /* Set width to 250px */
  /* Remove max-height and max-width properties */
  /* Make the logo responsive */
  height: auto;
}
</style>
