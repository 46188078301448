<template>
  <div>
    <v-card class="form-card" outlined>
      <v-card-title class="form-card-title">Add Entry</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="submitForm">
          <h2 class="section-title">Need</h2>
          <v-text-field v-model="need" variant="solo-filled" class="input-fields need-input" ref="needInput" inputmode="numeric"></v-text-field>
          <h2 class="section-title">SKU</h2>
          <v-text-field v-model="sku" required variant="solo-filled" class="input-fields sku-input" inputmode="none"></v-text-field>
          <div class="submit-button-container">
            <v-btn size="x-large" class="submit-button" type="submit">Submit</v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, update, child, get } from "firebase/database";
import { allproductsDB } from "@/firebase.js";

export default {
  data() {
    return {
      need: '',
      sku: '',
    };
  },
  methods: {
    async submitForm() {
      if (this.need === '') {
        this.need = '-';
      }

      if (this.sku === '') {
        console.error('Please fill in the SKU field.');
        return;
      }

      const skuRef = ref(allproductsDB, "products");
      let snapshot = null;
      
      try {
        snapshot = await get(skuRef);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
      
      let productID = null;
      
      if (snapshot.exists()) {
        snapshot.forEach((childSnapshot) => {
          let childData = childSnapshot.val();
          if (childData.SKU === this.sku) {
            productID = childSnapshot.key;
          }
        });
      } else {
        console.error('SKU not found.');
        return;
      }

      if (productID) {
        const productRef = child(skuRef, productID);

        try {
          await update(productRef, { Need: this.need });
          console.log('Entry updated successfully!!!');
          this.need = '';
          this.sku = '';
        } catch (error) {
          console.error('An error occurred');
          console.error(error);
        }
      } else {
        console.error('SKU not found.');
      }
    },
  },
};
</script>

<style scoped>
.form-card {
  max-width: 650px;
  margin: 0 auto;
  border: 1px solid #444;
  background-color: #1d1d1d;
}

.form-card-title {
  background-color: #102ef0;
  color: #fff;
  padding: 5px 15px;
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
}

.form-card-text {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.submit-button-container {
  display: flex;
  justify-content: center;
  margin-top: -5px;
}

.submit-button {
  color: #fff;
  margin-bottom: 5px;
  margin-top: 0px;
  padding: 10px 50px;
}

.input-fields {
  max-width: 350px;
  margin: 0 auto;
}

.section-title {
  text-align: center;
  margin-bottom: 5px;
  font-size: 18px;
  margin-top: -15px;
  padding-top: 10px;
}

.need-input {
  width: 100px;
}
</style>
