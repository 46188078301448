import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

// Vuetify
import 'vuetify/styles'; // Import Vuetify styles
import { createVuetify } from 'vuetify'; // Import the createVuetify function
import * as components from 'vuetify/components'; // Import all Vuetify components
import * as directives from 'vuetify/directives'; // Import all Vuetify directives

// Firebase configuration
const firebaseConfig = {
  // Your Firebase configuration
  apiKey: "AIzaSyD8J4_PeB9m_te7_DC0iFdqjORIgB_K-KQ",
  authDomain: "crawlerproject-e0d20.firebaseapp.com",
  databaseURL: "https://crawlerproject-e0d20-tt.firebaseio.com",
  projectId: "crawlerproject-e0d20",
  storageBucket: "crawlerproject-e0d20.appspot.com",
  messagingSenderId: "640298099084",
  appId: "1:640298099084:web:5f018e9ca32e2f47d5b6a9",
  measurementId: "G-YCZPGFVCNK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence);

// Initialize store (Vuex)
store.dispatch('initializeAuth');

// Create and configure Vuetify
const vuetify = createVuetify({
  components,
  directives,
  theme: { defaultTheme: 'dark' },
});

// Create Vue application and mount it
createApp(App).use(router).use(store).use(vuetify).mount('#app');
